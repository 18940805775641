import * as React from "react";
import styled from "styled-components";
import "../css/typographyc.css";

const StyledNotFound = styled.div`
background-color: var( --second-dark);
width: 100%;
height: 100%;
min-height: 100vh;
display: flex;
justify-content: center;
align-items: center;
font-family: "Russo_one";
color: white;
font-size: 30px;
@media (min-width: 1200px) {
  font-size: 40px;
}
`;
const NotFoundPage = () => {
  return (
    <StyledNotFound>
      Esta página no existe
    </StyledNotFound>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
